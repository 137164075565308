
export const fromconfig = () => {
  return [
    {
      label: '主借人：',
      prop: 'mainBorrower',
      value: '',
      span: 5
    },
    {
      label: '融资金额(元)：',
      prop: 'financeAmount',
      value: '',
      type: 'Amount',
      span: 5
    },
    {
      label: '放款金额(元)：',
      prop: 'loanAmount',
      value: '',
      type: 'Amount',
      span: 5
    },
    {
      label: '还款方式：',
      prop: 'repayTypeName',
      value: '',
      span: 5
    },
    {
      label: '放款日期：',
      prop: 'loanDate',
      value: '',
      span: 5
    },
    {
      label: '起息日期：',
      prop: 'startDate',
      value: '2',
      span: 5
    },
    {
      label: '到期日期：',
      prop: 'endDate',
      value: '',
      span: 5
    },
    {
      label: '手续费率(%)：',
      prop: 'serviceRate',
      value: '',
      span: 5
    },
    {
      label: '利率(%)：',
      prop: 'interestRate',
      value: '',
      span: 4
    }
  ]
}
export const columnsConfig = (content) => {
  if (content.type === 'CK') {
    return [
      {
        label: '还款期数',
        prop: 'repayPeriods'
      },
      {
        label: '还款时间',
        prop: 'preRepayDate',
        formatter: row => {
          return content.preRepayDate(row.preRepayDate)
        }
      },
      {
        label: '还款主体',
        prop: 'repaySubject'
      },
      {
        label: '还款类型',
        prop: 'repayType',
        formatter: row => {
          return content.repayType(row.repayType)
        }
      },
      {
        label: '应还金额(元)',
        prop: 'preRepayAmountd',
        formatter: row => {
          return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '费用小计(元)',
        prop: 'loanAmount'
      }
    ]
  } else if (content.type === 'BG') {
    return [
      {
        label: '还款期数',
        prop: 'repayPeriods',
        minWidth: '120px',
        HiddenOverflow: true
      },
      {
        label: '还款时间',
        prop: 'preRepayDate',
        minWidth: '120px',
        HiddenOverflow: true,
        formatter: row => {
          return content.preRepayDate(row.preRepayDate)
        }
      }, {
        label: '还款类型',
        prop: 'repayType',
        minWidth: '120px',
        HiddenOverflow: true,
        formatter: row => {
          return content.repayType(row.repayType)
        }
      },

      {
        label: '还款主体',
        prop: 'repaySubject',
        minWidth: '120px',
        HiddenOverflow: true
      },
      {
        label: '应还金额(元)',
        prop: 'preRepayAmount',
        minWidth: '120px',
        HiddenOverflow: true,
        formatter: row => {
          return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '操作',
        prop: 'action',
        width: '155px',
        fixed: 'right'
      }
    ]
  } else if (content.type === 'HKDJ') {
    return [
      {
        label: '还款期数',
        prop: 'repayPeriods'
      },
      {
        label: '应还日期',
        prop: 'preRepayDate'
      },
      {
        label: '还款类型',
        prop: 'repayType'
      },
      {
        label: '还款主体',
        prop: 'repaySubject'
      },
      {
        label: '应还金额(元)',
        prop: 'preRepayAmountd',
        formatter: row => {
          return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '已还金额(元)',
        prop: 'actualRepayAmount',
        formatter: row => {
          return (row.actualRepayAmount ? row.actualRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '剩余未还金额(元)',
        prop: 'remainAmount',
        formatter: row => {
          return (row.remainAmount ? row.remainAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '状态',
        prop: 'repayStatus',
        formatter: row => {
          const data = content.repayStatusList.find(item => parseInt(item.dictId) === row.repayStatus)
          if (data) {
            return data.dictName
          }
        }
      },
      {
        label: '备注',
        prop: 'memo'
      },
      {
        label: '操作',
        prop: 'action',
        width: '155px',
        fixed: 'right'
      }
    ]
  }
}
// 还款期数
export const repaymentNumberoptions = (content) => {
  return [
    {
      label: '1',
      value: 1
    },
    {
      label: '2',
      value: 2
    },
    {
      label: '3',
      value: 3
    },
    {
      label: '4',
      value: 4
    },
    {
      label: '5',
      value: 5
    },
    {
      label: '6',
      value: 6
    },
    {
      label: '7',
      value: 7
    },
    {
      label: '8',
      value: 8
    },
    {
      label: '9',
      value: 9
    },
    {
      label: '10',
      value: 10
    },
    {
      label: '11',
      value: 11
    },
    {
      label: '12',
      value: 12
    }
  ]
}
