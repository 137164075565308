// 还款计划详情
<template>
  <div>
    <backtitle :lable='$route.query.name'/>
    <div class="bgw" :class="{'with-footer': type === 'BG'}">
      <div>
        <!-- 头部标题 -->
        <titleTemplate :name="`供应商：${finBusuinessInfoVO.gysCompanyName}`">
          <template slot="operation">
            <span>业务编号：{{ finBusuinessInfoVO.businessNo }}</span>
          </template>
        </titleTemplate>
        <!-- 借款信息 -->
        <el-collapse v-model="activeNames">
          <el-collapse-item :name="index" v-for="(item,index) of finPlanInfoVO" :key="index">
            <div class="info">
              <el-row>
                <el-col
                  :span="item.span"
                  v-for="(item, index) of item.slice(0,4)"
                  :key="index"
                  class="item"
                >
                  <div class="row cont-center">
                    <div class="redbox"></div>
                    <div class="label" style="white-space: nowrap">{{ item.label }}</div>
                    <el-tooltip class="item" effect="dark" :content="item.value.toString()" placement="top" :disabled="showTooltip">
                      <div class="value" :class="{red:item.label==='还款方式：'}"  style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis"  @mouseover="onShowNameTipsMouseenter($event)">{{item.value}}</div>
                    </el-tooltip>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :span="item.span"
                  v-for="(item, index) of item.slice(4,9)"
                  :key="index"
                  class="item"
                >
                  <div class="row cont-center">
                    <div class="redbox"></div>
                    <div class="label">{{ item.label }}</div>
                    <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis" >{{item.value }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
       <!--收款账户-->
      <div class="box" style="display: none">
        <title-name-slot title="收款账户"></title-name-slot>
        <div class="accountabel">
          <div style="width: 49%" v-for="item of 1" :key="item">
            <repayment-account
              :bankAccount="finRepayBankAccountInfo"
              addColor="#ffffff"
              isRedbox
              :Index="item">
            </repayment-account>
          </div>

        </div>
      </div>

       <!--表格数据-->
      <div class="box">
        <title-name-slot title="还款计划"></title-name-slot>
        <div v-if="type === 'BG'" class="addbtn">
          <div class="addbutton" @click="addPlan">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div v-if="type === 'HKDJ'" class="addbtn">
          <base-button label="还款流水" @click="openDialog('L','','还款流水')" icon='iconfont iconliushui'></base-button>
        </div>
        <!-- 表格 -->
        <base-table
          :columns="columns"
          :showPage="false"
          :tableAttrs="{
          data: tableData,
          stripe: true,
          spanMethod:setSpanMethod,
          cellStyle:setcellStyle
        }"
          :type="type"
          ref="tableData"
          :webPage="false"
          :is-caculate-height="true"
        >
         <template slot="memo" slot-scope="scope">
          <div :class="{red:scope.row.repayStatus===90}">
            {{scope.row.memo}}
          </div>
          </template>
          <template slot="repayStatus" slot-scope="scope">
          <div :class="{red:scope.row.repayStatus===90}">{{getRepayStatus(scope.row.repayStatus)}}</div>
        </template>
          <template slot="action" slot-scope="scope">
            <icon-button @click.prevent="deletePlan(scope.row, scope.$index)" v-if="type === 'BG' && scope.row.repayType !== 2" content="删除"
                        icon="iconfont iconshanchu1"></icon-button>
            <icon-button @click.prevent="openDialog('D', scope.row,'还款登记')"
                        v-if="type === 'HKDJ'&& parseInt(scope.row.repayStatus) !== 30 && $route.query.edit === 'dj' && scope.row.repayType !== 2"
                        content="登记" icon="iconfont icondengji"></icon-button>
            <icon-button @click.prevent="openDialog('C', scope.row,'还款登记详情')" v-if="type === 'HKDJ'" content="查看"
                        icon="iconfont iconchakan"></icon-button>
          </template>
          <template slot="repayPeriods" slot-scope="scope">
            <div v-if="scope.row.addtype && type === 'BG'">
              <el-select
                v-model="tableData[scope.$index].repayPeriods"
                placeholder="请选择"
                :disabled="parseInt(scope.row.repayType) === 2"
              >
                <el-option
                  v-for="item in repaymentNumberoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-else>{{ scope.row.repayPeriods }}</div>
          </template>
          <template slot="preRepayDate" slot-scope="scope">
            <div v-if="scope.row.addtype && type === 'BG'">
              <el-date-picker
                :picker-options="setpreRepayDateTime()"
                :clearable="false"
                v-model="scope.row.preRepayDate"
                type="date"
                :disabled="parseInt(scope.row.repayType) === 2"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <div v-else>{{ preRepayDate(scope.row.preRepayDate) }}</div>
          </template>
          <template slot="repaySubject" slot-scope="scope">
            <div v-if="scope.row.addtype && type === 'BG'">
              <el-select
                v-model="scope.row.repaySubject"
                placeholder="请选择"
                @change="updateFinPlanId($event,scope)"
                @focus="repaySubjectFocus(scope.row)"
                :disabled="parseInt(scope.row.repayType) === 2"
              >
                <el-option
                  v-for="item in subjectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

            </div>
            <div style="overflow: hidden;text-overflow: ellipsis" v-else>{{ scope.row.repaySubject }}</div>
          </template>

          <template slot="repayType" slot-scope="scope">
            <div v-if="scope.row.addtype && type === 'BG'">
              <el-select v-if="tableData[scope.$index].repayType === 2" disabled v-model="tableData[scope.$index].repayType">
                <el-option label="本息" :value="2"></el-option>
              </el-select>
              <el-select
                v-else
                v-model="tableData[scope.$index].repayType"
                @change="subjectListchange(tableData[scope.$index].repayType,scope)"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in subjectoPtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div v-else>{{ parseInt(scope.row.repayType) === 0 ? '本金' : parseInt(scope.row.repayType) === 1?'服务费': '本息' }}</div>
          </template>
          <template slot="preRepayAmount" slot-scope="scope">
            <div v-if="scope.row.addtype && type === 'BG'">
              <amount-input type="Y" v-model="tableData[scope.$index].preRepayAmount" :disabled="parseInt(scope.row.repayType) === 2" @input="inputPreRepayAmount(scope.$index,tableData[scope.$index].preRepayAmount)"></amount-input>
            </div>
            <div v-else>{{ scope.row.preRepayAmount }}</div>
          </template>
          <template slot="loanAmount" slot-scope="scope">
            <p>{{ setSum(scope.row.repayPeriods) }}</p>
          </template>
        </base-table>
      </div>
      <!--      登记流水,登记详情-->
      <repaymentre-gistration
        :Visible.sync="Visible"
        :form="diaLogForm"
        :tableData="registerList"
      ></repaymentre-gistration>
    </div>

    <div class="footer" v-if="type === 'BG'">
      <base-button label="提 交" @click="commitPlanData"></base-button>
      <base-button label="关 闭" type="default" @click="$router.back()"></base-button>
    </div>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import Backtitle from '@/pages/business/components/backtitle.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import TitleTemplate from '@/pages/business/components/titleTemplate.vue'
import AmountInput from '@/components/input/amount-input/amount-input'
import RepaymentreGistration from './components/repayment-registration.vue'
import RepaymentAccount from './components/repayment-account'

import { fromconfig, columnsConfig, repaymentNumberoptions } from './utils/info.config'
import { PlanDetailApi, repayregistApi } from '@/api/businessApi'
import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'
import BaseTable from '@/components/common/table/base-table/base-table.vue'

export default {
  components: {
    BaseTable,
    RepaymentreGistration,
    TitleTemplate,
    titleNameSlot,
    BaseButton,
    Backtitle,
    IconButton,
    AmountInput,
    RepaymentAccount
  },
  data () {
    return {
      activeNames: [],
      setDate: '',
      financeType: '',
      subjectList: [],
      type: '',
      repayStatusList: [],
      finBusuinessInfoVO: {},
      finRepayBankAccountInfo: [],
      fromConfig: [],
      Visible: false,
      diaLogForm: {},
      subjectoPtions: [
        {
          label: '本金',
          value: 0
        },
        {
          label: '服务费',
          value: 1
        }
      ],
      tableData: [],
      registerList: [],
      loanDate: '',
      spanArr: [], // 遍历数据时，根据相同的标识去存储记录
      pos: 0, // 二维数组的索引
      hoverOrderArr: [],
      OrderIndexArr: [],
      rowIndex: '-1',
      delKeyIds: [],
      finPlanInfoVO: [],
      list: [],
      showTooltip: false,
      finPlanId: ''
    }
  },
  computed: {
    repaymentNumberoptions () {
      return repaymentNumberoptions(this)
    },
    columns () {
      return columnsConfig(this)
    },
    PlanDetailApi () {
      return PlanDetailApi
    },
    repayregistApi () {
      return repayregistApi
    }
  },
  created () {
    this.type = this.$route.query.type
    console.log(this.type)
  },
  mounted () {
    this.initData()
    this.setRepayStatus()
  },
  methods: {
    getRepayStatus (repayStatus) {
      const data = this.repayStatusList.find(item => parseInt(item.dictId) === repayStatus)
      if (data) {
        return data.dictName
      }
    },
    inputPreRepayAmount (index, data) {
      if (data && data === '0') {
        this.tableData[index].preRepayAmount = ''
      }
    },

    /***
     * 设置时间选择组件的时间范围
     * @returns {boolean|{disabledDate(*): (*|boolean)}}
     */
    setpreRepayDateTime () {
      const loanDate = this.loanDate
      return {
        disabledDate (time) {
          if (loanDate) {
            return time.getTime() < new Date('1999-12-31')
          } else {
            return time.getTime() < new Date('1900-01-01')
          }
        }
      }
    },
    /**
     * 时间格式化
     * @param data
     * @returns {string}
     */
    preRepayDate (data) {
      return formatDate(data, 'YY-MM-DD')
    },

    /***
     *打开弹出框
     * @param type
     * @param row
     */
    openDialog (type, row, title) {
      const obj = {
        type: type,
        title: title,
        data: row
      }
      if (type === 'L' || type === 'C') {
        this.diaLogForm = { ...obj }
        type === 'L' ? this.repaymentflowDilog(row) : this.getRepayregistRegist(row)
      }

      if (type === 'D') {
        this.diaLogForm = {
          ...obj,
          account: this.finRepayBankAccountInfo,
          delay: this.setRegistrationTime(row.repaySubject)
        }
      }
      this.Visible = true
    },
    /***
     * 删除计划
     * @param row
     * @param index
     */
    deletePlan (row, index) {
      if (row.keyId) {
        this.$stateConfirm({
          title: '提示',
          message: '已有数据确认删除？',
          show: true,
          type: 'warning'
        }).then(() => {
          this.tableData.splice(index, 1)
          this.delKeyIds.push(row.keyId)
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    /***
     *  新增还款计划
     */
    addPlan () {
      this.tableData.unshift({ addtype: true })
    },
    /***
     * 获取列表
     */
    setRepayStatus () {
      const repayStatusList = getDictLists('REPAY_STATUS')
      this.repayStatusList = repayStatusList
    },

    /**
     *判断内容是否溢出添加气泡
     * @param e
     */
    onShowNameTipsMouseenter (e) {
      const target = e.target
      const containerLength = target.clientWidth
      const textLength = target.scrollWidth
      this.showTooltip = !(textLength > containerLength)
    },
    /***
     * 数据初始化
     */
    initData () {
    // 还款计划详情
      if (['BG', 'CK'].includes(this.type)) {
        this.tableData = []
        this.getRepaymentPlanDetail()
      }
      // 还款登记详情
      if (this.type === 'HKDJ') {
        this.getRepayregistDetail()
      }
    },

    /***
     *  还款类型
     * @param index
     */
    subjectListchange (index) {
      this.getrepaySubject(index)
    },

    /**
     * 获取还款主体
     * @param repaySubject
     */
    getrepaySubject (repaySubject) {
      const params = {
        businessId: this.finBusuinessInfoVO.keyId,
        repayType: repaySubject
      }
      this.PlanDetailApi.getSubjectList(params)
        .then(res => {
          if (res.data) {
            this.subjectList = res.data.map((item) => {
              item = {
                label: item.mainBorrower,
                value: item.mainBorrower,
                finPlanId: item.keyId
              }
              return item
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    /***
     *优化还款计划数据过多获取还款主提较慢
     * @param row
     * @returns {*}
     */
    repaySubjectFocus (row) {
      if (typeof row.repayType !== 'undefined') {
        this.getrepaySubject(row.repayType)
      } else {
        return this.$message.error('无数据,请先选择还款类型')
      }
    },

    /***
     *更新finPlanId
     * @param event
     * @param scope
     */
    updateFinPlanId (event, scope) {
      if (scope.row.repayType === 0) {
        const data = this.list.find(item => event === item.mainBorrower)
        this.tableData[scope.$index].finPlanId = data.keyId
        this.$set(this.tableData, scope.$index, this.tableData[scope.$index])
      }
    },

    /***
     * 还款计划详情
     */
    getRepaymentPlanDetail () {
      this.PlanDetailApi.getPlanDetail({ loanId: this.$route.query.loanId })
        .then((res) => {
          if (res.data) {
            this.finBusuinessInfoVO = res.data.finBusuinessInfoVO
            this.finRepayBankAccountInfo = res.data.repayBankAccount
            this.financeType = res.data.finResultInfo ? res.data.finResultInfo.financeType : ''
            this.loanDate = res.data.finResultInfo ? res.data.finResultInfo.preLoanDate : ''
            if (this.finBusuinessInfoVO.capitalSideName === '邮储银行') {
              const listData = []
              if (res.data.finRepayPlan && res.data.finRepayPlan.length > 0) {
                res.data.finRepayPlan.forEach(ele => {
                  if (ele.repayType !== 0) {
                    listData.push(ele)
                  }
                })
              }
              this.tableData = this.repayPeriodsSort(listData)
            } else {
              this.tableData = this.repayPeriodsSort(res.data.finRepayPlan)
            }
            if (res.data.finRepayPlan) {
              this.finPlanId = res.data.finRepayPlan[0].finPlanId
            }

            this.list = res.data.finPlanInfoVO
            this.fromDataArray(res.data)
            this.getSpanArr(this.tableData)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 排序比较函数
     * @param a
     * @param b
     * @returns {number}
     */
    sortRepayPeriods (a, b) {
      return a.repayPeriods - b.repayPeriods
    },
    /***
     * 按照还款期数排序
     * @param list
     * @returns {*}
     */
    repayPeriodsSort (list) {
      if (list) {
        list = list.map(item => {
          item.addtype = true
          item.preRepayDate = item.preRepayDate < 0 ? new Date().getTime() : item.preRepayDate
          return item
        })
        list = list.sort(this.sortRepayPeriods)
      }
      return list
    },

    /***
     * 筛选供应商
     * @param repaySubject
     * @returns {*}
     */
    screenArray (repaySubject) {
      if (this.finPlanInfoVO) {
        for (const val of this.finPlanInfoVO) {
          for (const k of val) {
            if (k.value === repaySubject) {
              return val
            }
          }
        }
      }
    },

    /***
     * 登记时间筛选设置
     * @param repaySubject
     * @returns {string|*}
     */
    setRegistrationTime (repaySubject) {
      const time = [] // 保存时间
      const arr = this.screenArray(repaySubject)
      if (arr) {
        const val = arr && arr.find(item => item.prop === 'loanDate')
        return val.value
      } else {
        const item = this.finPlanInfoVO
        item.forEach(val => {
          const _val = val.find(item => item.prop === 'loanDate')
          time.push(new Date(_val.value))
        })
      }
      return formatDate(Math.min(...time), 'YY-MM-DD')
    },

    /***
     * 校验还款计划是否填写完整
     */
    validatePlanVal () {
      const addArr = this.tableData
      const action = this.columns.filter(item => item.prop !== 'action')
      for (const k of addArr) {
        for (const m of action) {
          if (k[m.prop] === '' || typeof k[m.prop] === 'undefined') {
            return m
          }
        }
      }
    },

    /***
     *校验还款计划的金额是否大于本金
     */
    validateMoney () {
      for (const val of this.tableData) {
        if (val.repayType === 0) {
          const data = this.screenArray(val.repaySubject)
          if (data) {
            const loanAmount = data.find(item => item.prop === 'loanAmount').value
            if (loanAmount) return Number(loanAmount.replace(',', '')) < val.preRepayAmount
          }
        }
      }
    },

    /***
     * 数据提交
     */
    commitPlanData () {
      if (!this.tableData.length) {
        this.$message.error('至少需要一组还款计划')
        return
      }

      if (this.validatePlanVal()) {
        this.$message.error(`${this.validatePlanVal().label}未填写`)
        return
      }

      if (this.validateMoney()) {
        this.$message.error('本金不能大于放款金额')
        return
      }

      this.tableData = this.tableData.map(item => {
        item.businessId = this.finBusuinessInfoVO.keyId
        item.finPlanId = this.finPlanId
        return item
      })

      const params = {
        finRepayPlan: this.tableData,
        delKeyIds: this.delKeyIds
      }
      this.PlanDetailApi.addRepaymentData(this.list[0].keyId, params)
        .then(res => {
          if (res) {
            this.$message.success('提交成功')
            this.$router.back()
          }
        })
        .catch(error => {
          console.log(error)
          return false
        })
    },
    /***
     * 融资金额计算
     * @param arr
     * @returns {string}
     */
    // financeAmount (arr) {
    //   if (arr && Array.isArray(arr)) {
    //     const sum = arr.reduce((prev, cur) => {
    //       console.log(prev, cur, '88888888888')
    //       return cur.financeAmount + prev
    //     }, 0)
    //     return sum
    //   }
    // },
    /***
     * 还款方式
     * @param way
     * @returns {string|string}
     */
    setRepaymentWay (way) {
      if (way.finRepayStyleDTO) {
        return parseInt(way.finRepayStyleDTO.repayStatus) === 0 ? '一次性还本付息' : '分批还款'
      }
    },
    /***
     * 数据处理
     * @param data
     */
    fromDataArray (data) {
      const val = data.finPlanInfoVO
      if (!val) return
      this.finPlanInfoVO = []
      val.forEach((item, index) => {
        const obj = {}
        this.fromConfig = fromconfig(this)
        obj.financeAmount = item.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
        obj.loanAmount = item.finLoanInfoVO.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
        obj.mainBorrower = item.mainBorrower
        obj.repayTypeName = this.setRepaymentWay(item)
        obj.loanDate = formatDate(item.finLoanInfoVO.loanDate, 'YY-MM-DD')
        obj.startDate = formatDate(item.finLoanInfoVO.startDate, 'YY-MM-DD')
        obj.endDate = formatDate(item.finLoanInfoVO.endDate, 'YY-MM-DD')
        obj.serviceRate = item.finLoanInfoVO.serviceRate
        obj.interestRate = item.finLoanInfoVO.interestRate
        this.setValue(obj)
        this.finPlanInfoVO.push(this.fromConfig)
        this.activeNames.push(index)
      })
    },
    /**
     * 赋值
     * @param obj
     */
    setValue (obj) {
      for (const [key, value] of Object.entries(obj)) {
        for (const val of this.fromConfig) {
          if (key === val.prop) {
            val.value = value
          }
        }
      }
    },
    /***
     * 合并标识处理
     * @param data
     */
    getSpanArr (data) {
      const that = this
      // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
      that.spanArr = []
      that.pos = 0
      // 遍历数据
      data.forEach((item, index) => {
      // 判断是否是第一项
        if (index === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
        // 不是第一项时，就根据标识去存储
          if (data[index].repayPeriods === data[index - 1].repayPeriods) {
          // 查找到符合条件的数据时每次要把之前存储的数据+1
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
          // 没有符合的数据时，要记住当前的index
            this.spanArr.push(1)
            this.pos = index
          }
        }
      })
    },

    /***
     * 合并单元格
     * @param rowIndex
     * @param columnIndex
     * @returns {{colspan: (number), rowspan: *}|{colspan: number, rowspan: number}}
     */
    objectSpanMethod (rowIndex, columnIndex) {
    // 页面列表上 表格合并行 -> 第几列(从0开始)
    // 需要合并多个单元格时 依次增加判断条件即可
      if (columnIndex === 5) {
      // 二维数组存储的数据 取出
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },

    /***
     * 单元格合并设置
     * @param rowIndex
     * @param columnIndex
     * @returns {{colspan: number, rowspan: *}|{colspan: number, rowspan: number}|boolean}
     */
    setSpanMethod ({
      rowIndex,
      columnIndex
    }) {
      if (this.type === 'CK') {
        return this.objectSpanMethod(rowIndex, columnIndex)
      }

      if (this.type === 'HKDJ' || this.type === 'BG') {
        return false
      }
    },

    /****
     * 最小消费计算
     * @param repayPeriods
     * @returns {string}
     */
    setSum (repayPeriods) {
      const result = this.tableData.filter((item) => item.repayPeriods === repayPeriods)
      const sum = result.reduce(function (prev, cur) {
        return prev + cur.preRepayAmount
      }, 0)
      return sum.toLocaleString('en-US', { minimumFractionDigits: 2 })
    },

    /***
     * 指定列添加样式
     * @param columnIndex
     * @returns {string}
     */
    setcellStyle ({ columnIndex }) {
      if (this.type === 'CK' && columnIndex === 5) {
        return ' background-color: #FFFFFF'
      }
    },

    /**
     * 还款登记详情
     * @constructor
     */
    getRepayregistDetail () {
      this.Visible = false
      const params = {
        loanId: this.$route.query.loanId
      }
      this.repayregistApi.getRepayregistDetail(params)
        .then(res => {
          if (res.data) {
            this.finBusuinessInfoVO = res.data.finBusuinessInfoVO
            this.finRepayBankAccountInfo = res.data.repayBankAccount
            if (this.finBusuinessInfoVO.capitalSideName === '邮储银行') {
              const listData = []
              if (res.data.finRepayPlan && res.data.finRepayPlan.length > 0) {
                res.data.finRepayPlan.forEach(ele => {
                  if (ele.repayType !== 0) {
                    listData.push(ele)
                  }
                })
              }
              this.tableData = listData
            } else {
              this.tableData = res.data.finRepayPlan
            }

            this.fromDataArray(res.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    /**
     * 查看还款登记
     * @param data
     */
    getRepayregistRegist (data) {
      this.repayregistApi.getRepayregistRegist({ repayPlanKeyId: data.keyId })
        .then(res => {
          this.registerList = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    /***
     * 还款流水信息
     * @param data
     * @constructor
     */
    repaymentflowDilog (data) {
      const params = {
        finPlanId: this.tableData ? this.tableData[0].finPlanId : '',
        businessId: this.tableData ? this.tableData[0].businessId : ''
      }
      this.repayregistApi.getRepayregisttSummery(params)
        .then(res => {
          console.log(res)
          this.registerList = res.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>
<style lang="scss" scoped>
.footer {
  text-align: center;
  margin: 0 0 10px;
  padding: 14px 0;
  // box-shadow: 0 0 13px 2px rgba(255, 181, 77, 0.4);
  box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
  background: #ffffff;
}

.accountabel {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .content {
    background: #f9f9f6;
    padding: 14px 0 0 10px;
    width: 100%;
    margin-top: 11px;
  }
}

.info {
  background: #fff;
  padding: 10px 20px;
  margin: 0 10px;
}

.submit {
  margin: auto;
}

.addbtn {
  padding: 15px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  /deep/ .iconliushui {
    font-size: 20px;
  }

  button {
    height: 34px;
    padding: 0;
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
.red{
  color:#EE1C1C !important;
}
.box {
  padding: 15px;

  .title {
    text-align: center;
  }

  .right {
    width: 1000px;
    border: 0.5px solid #333333;
    padding: 10px;

    .label {
      display: inline-block;
      width: 400px;
      padding: 0 15px;
    }
  }
}

.item {
  padding: 7px 0;
  font-size: 14px;
  font-weight: 400;
  color: #38373a;
}

.itemborder {
  border-bottom: 1px dashed #D9E5EE;
}

.row {
  display: flex;
}

.cont-center {
  align-items: center;
}

.jaround {
  justify-content: space-around;
}

.bgw {
  background: #fff;
  height: calc(100% - 31px);
  overflow-y: auto;
  &.with-footer{
    height: calc(100% - 91px);
  }
}

/deep/ .el-table .success-row {
  background: #f5f7fa;
}
.redbox {
  width: 10px;
  height: 10px;
  background: #2862E7;
  margin-right: 8px;
  flex-shrink:0
}
</style>
